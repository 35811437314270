import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BannerMobileApp, BannerMobileWallet } from "../../../components/Blog/postElements/BannerMobile";
import { BlogTable } from "../../../components/Blog/postElements/BlogTable";
import { NewsletterBanner } from "../../../components/NewsletterBanner";
import { Hint } from "../../../components/Blog/postElements/Hint";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
import { HtmlElement } from "../../../components/Blog/postElements/HtmlElement";
export const hints = ['How much application code needs to be written from scratch when starting a new project?', 'How easy it is for beginners to learn the technology?', 'How many UI components must be written from scratch?', 'How many plugins are available to simplify application writing and maintenance?', 'How popular among developers is this technology?', 'How is the performance? 10 - perfect, no dropped frames 1 - choppy animations and lag', 'What level of access to native functions, such as geolocation, Bluetooth, etc?', 'How many companies pick this technology?', 'How quickly can you make changes, and how soon are results seen after writing?', 'How easy it is to create, maintain and evolve an application rich in custom animations and unconventional UI?', 'How many outdated solutions, that can hinder development, are there?', 'How many experts are available and how easy it is to begin working with them?'];
export const _frontmatter = {
  "title": "What’s best in 2021: Ionic, React Native or Flutter?",
  "categories": ["mobile applications", "business", "technical", "flutter"],
  "authors": ["Marek Gocał"],
  "abstract": "Already considered using a cross-platform technology to help you deliver on multiple platforms? The choice of technology will impact your business, development team and users for years to come. Let's think it through. Read the article to learn more.",
  "imageSource": "./main.png",
  "link": "ionic-reactnative-flutter-comparison",
  "date": "2021-03-08T10:51:12.477Z",
  "seoTitle": "Ionic vs React Native vs Flutter",
  "seoDescription": "What’s the best cross-platform technology in 2021: Ionic, React Native or Flutter? Cross-Platform comparison, Ionic vs Flutter vs React native vs native app A detailed overview of the cross-platform technologies, Ionic vs Flutter vs React native",
  "seoKeyPhrase": "modern mobile cross-platform technologies comparison (ionic vs react native vs flutter)",
  "seoKeyWords": "ionic, flutter, react native, mobile application development, mobile apps development, flutter app development company, IOS, Android, Flutter vs react native, Flutter vs ionic, React native vs Ionic, Flutter vs react native vs Ionic, flutter performance, react native performance, iconic performance"
};
const layoutProps = {
  hints,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostIntro title="In this article you will find:" description={<ul><li>Modern mobile cross-platform technologies, an introduction</li><li>A description of each of the technologies featured in this article</li><li>A comparison of the three technologies</li><li>The pros and cons of each technology</li></ul>} imageUrl={"/img/6.-mobile-cross-platform-technologies.png"} mdxType="PostIntro" />
    <HtmlElement element={<h2 id="Introduction" style={{
      marginTop: '20px'
    }}>Introduction</h2>} mdxType="HtmlElement" />
    <p><strong parentName="p">{`If you want your next application to debut across all major platforms, you’ve probably already considered using a cross-platform technology to help you deliver that result. You’re also likely aware of the trio of modern solutions mentioned in this article’s title. Each has many supporters, who will speak up for the one they’ve used previously! But wait a moment before making that choice.`}</strong></p>
    <p>{`Let’s think it through. That choice will impact your business, development team and users for years to come. Are you the one ensuring that a tech decision matches the business’s objectives and budget? Or maybe you are the lead technologist and, after all the shouting, the choice is yours (meaning you get the blame if the performance sucks)?`}</p>
    <p>{`What you need is the ability to make a well-informed choice. Happily, you’re in the right place as we’ve already been through that learning journey for you.`}</p>
    <p>{`This article will help you select the mobile cross-platform development technology that will work best.`}</p>
    <p>{`At the end of this article, we make our recommendations. You could jump there and read it, but you’d be missing a detailed comparison of the three technologies, written to help you select the one that works best given your goals and circumstances. Our counsel?… Keep reading, get up to speed, then choose.`}</p>
    <p>{`Let’s begin by reminding ourselves of the main attributes of each technology.`}</p>
    <h2 {...{
      "id": "Ionic"
    }}>{`Ionic`}</h2>
    <p>{`First up is `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{`. A complete, open-source SDK for hybrid mobile app development created by Drifty Co. It runs with a mixture of native code and web code, providing full access to native functionality. `}<strong parentName="p"><em parentName="strong">{`The Ionic application is, therefore, just a web page running in a native app shell.`}</em></strong></p>
    <p>{`It utilises native hardware acceleration features in the browser (such as CSS animations) and optimises rendering (avoiding expensive DOM manipulation). This framework routes CSS animation transitions and transforms to the GPU, to maximise processor use.`}</p>
    <p>{`So, when is `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` useful? Historically, `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` is used for hybrid app development, including well-known apps `}<a parentName="p" {...{
        "href": "https://www.marketwatch.com/site/mobile",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`MarketWatch`}</a>{` for stock-watchers, and `}<a parentName="p" {...{
        "href": "https://sworkit.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Sworkit`}</a>{` for fitness freaks. `}<strong parentName="p"><em parentName="strong">{`Its main benefit is the high reuse of code from web-apps written in Angular.`}</em></strong></p>
    <h2 {...{
      "id": "React-Native"
    }}>{`React Native`}</h2>
    <p>{`Next up is `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{`, an open-source mobile application framework created by Facebook. It makes Android and iOS applications, allowing developers to use React and each platform’s native capabilities.`}</p>
    <p>{`The working principles of `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` are virtually identical to React except that `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` does not manipulate the DOM via the Virtual DOM. Instead, the DOM runs in a background process that interprets the developer’s JavaScript, while the framework uses native UI components wrapped into the React stack.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` `}<strong parentName="p"><em parentName="strong">{`is famous for delivering Facebook, Instagram and Skype apps for iOS and Android platforms. As a consequence, it is very stable and reliable.`}</em></strong>{` One of its stand-out benefits is implementing separate visual components for different platforms using platform-specific code.`}</p>
    <h2 {...{
      "id": "Flutter"
    }}>{`Flutter`}</h2>
    <p>{`Like `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{`, `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` is a framework created by a well-known technology company, in this instance Google. `}<strong parentName="p"><em parentName="strong">{`It is the youngest technology on our list and, thanks to this, it is the most innovative solution.`}</em></strong>{` At the same time, Google has not yet fulfilled all of the promises it made regarding `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{`.`}</p>
    <p>{`Informed by the experience of its competitors, `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` uses its engine in a completely different way. Google wrote `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` in the low-level programming language C ++, which means they have substantial control over the framework’s resources. Consequently, this technology has a more decisive influence on the performance and the appearance of an application. `}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://flutter.dev",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Flutter`}</a>{` entirely abandons the native UI, taking full control by drawing every element itself, which means it has a greater say on what’s visible on-screen.`}</em></strong></p>
    <p>{`These benefits go hand in hand with a cost: developers must learn a less-popular programming language called Dart. Dart defines the application’s logic. When an experienced developer is writing, it’s no big problem, but it certainly increases the initial barrier to entry.`}</p>
    <p>{`Amongst Google’s promises yet to be realised is a new sophistication for multiplatform apps. `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` targets one common codebase for Android, IOS, Windows, Mac, Linux and web. It is currently possible for such an application to exist, but it is only stable on Android, IOS and Linux. However, in this article, we are looking at mobile platforms, and here `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` already works exceptionally well. `}<strong parentName="p"><em parentName="strong">{`Even though the framework appeared on the market relatively recently, applications including `}<em parentName="em">{`Google Ads`}</em>{`, `}<em parentName="em">{`Google Pay`}</em>{`, `}<em parentName="em">{`Alibaba`}</em>{`, and banking applications for `}<em parentName="em">{`ING`}</em>{` and `}<em parentName="em">{`Nubank`}</em>{`, have already seen success.`}</em></strong></p>
    <BannerMobileApp title="" description={"Want to build a Flutter app?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Comparison"
    }}>{`Comparison`}</h2>
    <p>{`You’ll probably look at this question from a different angle when considering a technology stack transition than when choosing the technology for a new application. Let’s begin by looking at it from a business perspective.`}</p>
    <h3 {...{
      "id": "Business-perspective"
    }}>{`Business perspective`}</h3>
    <p>{`From a business point of view, the focus is on performance, cost of development, an easy way to swap developers if necessary, and maintaining the application as the project continues.`}</p>
    <p>{`When creating a mobile application, we want it to be visually attractive and to run smoothly. In terms of performance, some frameworks make this much more difficult to achieve than others. Until a few years ago, cross-platform applications came nowhere close to the performance of native applications. Even though today’s most efficient applications are specifically written for each platform, frameworks such as `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` have become almost equal. `}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://flutter.dev",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Flutter`}</a>{` is closest to the ideal performance shown by the native applications.`}</em></strong>{` By avoiding obvious development mistakes, we have built extremely smooth applications that operate up to 120 fps. `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` is a bit slower, but still quite good. As the oldest cross-platform technology in this comparison, `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` has the most difficulty maintaining a perfectly smooth operation.`}</p>
    <p>{`Costs arise from initial development and any time needed to maintain the application and continue to develop it in the future. `}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://flutter.dev",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Flutter`}</a>{` and `}<a parentName="em" {...{
            "href": "https://reactnative.dev",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`React Native`}</a>{` come out ahead from a cost perspective, as they both have quick reload features, making the development process very fast.`}</em></strong>{` What’s more, applications are easier to develop than apps made with other technologies. During the creation of `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` frameworks, the best solutions were adopted; ones already proven by native technologies over the years. At the same time, troublesome or outdated solutions were discarded. Due to this, these cross-platform frameworks use current best practices and offer a modern way to create mobile applications.`}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` is a good option if you already have a lot of web code. Native applications in this category are the least cost-effective as it means building two applications, one for each platform. However, even when creating an Android-only application, the development process and maintenance may cost less with `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` or `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{`. This financial benefit comes from the technologies’ modern tools and frameworks plus less platform fragmentation. And there’s a bonus. Extending to additional platforms in the future will be much easier.`}</p>
    <p>{`The last challenge is how easy it is to transfer the project from one development team to another. Sometimes it’s clear you are not getting along with the team that started developing your application. In this situation, as long as the contract supports it, you probably want to find other specialists to take over your project. Finding native developers is straightforward, and that’s unlikely to change for the next few years. However, in the case of `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{`, the situation now isn’t so different. More and more developers specialise in these frameworks every year, so finding a team to take over your project should not be too big a challenge. It’s not the same with `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` as this technology has not gained similar popularity, and there is no indication that the situation will change in the coming years.`}</p>
    <BannerMobileApp title="" description={"Want a mobile app consultation?"} mdxType="BannerMobileApp" />
    <h3 {...{
      "id": "Development-perspective"
    }}>{`Development perspective`}</h3>
    <p>{`It’s widely known in the software industry that some languages, or even entire technology stacks, are more challenging to master than others. There are usually two reasons for this. The first is that the purpose of some technologies is to solve complex problems. The second reason may be inadequate solutions within the technology or a continuing legacy that hinders work.`}</p>
    <p>{`Native technologies have been on the market for a long time. For many years, they have sought to provide easy and practical patterns that result in stable applications. Despite these established patterns, many of the changes result in an unfavourable legacy. The contemporary cross-platform technologies learnt from the experience of the native platforms, so, at the start, they could be based on best practices and avoid legacy issues.`}</p>
    <p>{`Of course, you have to learn the technology first. What’s easiest depends on the developer’s existing experience in a relevant part of the industry. If you have experience as a web developer, then `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` look to be the most straightforward options as you don’t have to learn everything from scratch. While `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` is a web-hybrid technology, `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` also has links with web technologies. Both use Javascript which makes them feel familiar to web developers.`}</p>
    <p>{`When it comes to learning Android and iOS separately, the entry threshold is much higher. First, both platforms use two languages. Android uses Java and Kotlin, and iOS uses Objective-C and Swift. In the case of `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{`, I place it midway between native and other cross-platform technologies. `}<strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://flutter.dev",
            "target": "_blank",
            "rel": "nofollow"
          }}>{`Flutter`}</a>{` uses the little-known language Dart. Dart is very similar to Java and javascript. It is a relatively simple, modern and highly efficient language to work with.`}</em></strong>{` The `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` framework is devoid of any quirks, so the learning itself is mostly very intuitive.`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` and `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` have both given rise to very dynamic communities. Active developers in these groups are closely involved in the technologies’ growth, although judging by Flutter’s fast expansion, this community will probably leave `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` far behind soon.`}</p>
    <p>{`Finally, we cannot forget one crucial aspect for developers - how easy it is to get a job in a given technological stack. If you’re starting, it won’t be easy in any of these technologies. Companies usually accept developers with one year of experience as an absolute minimum and are often not interested in hiring juniors. If, on the other hand, you already have programming experience, it will be easiest to find a job as an Android or iOS developer because most companies still rely on native solutions. `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` will be next in line, followed by `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{`. However, the situation is starting to change, and we suspect that `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` will gain more popularity over the next few years. With it, there will be more job offers in this technological stack than in `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{`.`}</p>
    <h2 {...{
      "id": "Architecture-comparison"
    }}>{`Architecture comparison`}</h2>
    <p>{`This section is for the more technical reader. Architectures can greatly impact both the ease of development and the feel of the resulting app. Take a look at the diagrams below to see the main differences.`}</p>
    <p><img parentName="p" {...{
        "src": "https://lh4.googleusercontent.com/uR0F6XcIbhYj7g_IDpmjlYLBA9o9NiESOfaRUjl0fWi6gvQIkTc-AfE0oV4vW2gsZQXzWuC4_AtybG4gKvJcxKU5bD5-5ab5bvTkJkIJT53Hbr1oCax4yqXdMPbzUcwVNWThatbi",
        "alt": "image6.png"
      }}></img></p>
    <p>{`So, what can we learn from this diagram? `}<strong parentName="p"><em parentName="strong"><em parentName="em">{`Amongst other things, we see that `}<a parentName="em" {...{
              "href": "https://flutter.dev",
              "target": "_blank",
              "rel": "nofollow"
            }}>{`Flutter`}</a>{` and `}<a parentName="em" {...{
              "href": "https://reactnative.dev",
              "target": "_blank",
              "rel": "nofollow"
            }}>{`React Native`}</a>{` allow apps to feature more platform-specific elements than `}<a parentName="em" {...{
              "href": "https://ionicframework.com",
              "target": "_blank",
              "rel": "nofollow"
            }}>{`Ionic`}</a>{` does. Also, we see that running code during development is a smoother experience when working with `}<a parentName="em" {...{
              "href": "https://flutter.dev",
              "target": "_blank",
              "rel": "nofollow"
            }}>{`Flutter`}</a>{`.`}</em></em></strong></p>
    <h2 {...{
      "id": "Cross-platform-mobile-technologies-comparison"
    }}>{`Cross-platform mobile technologies comparison`}</h2>
    <p>{`Let’s get down to the nuts and bolts. Use this table to discover if the technology you are considering will make you smile or present problems.`}</p>
    <BlogTable type='content' hints={hints} isGreyLastRow={true} boldNumbers={true} mdxType="BlogTable">
| category                                             | Ionic                                                                                           | React Native                                                                         | Flutter                                                                              | native development                                                                                                                          |
| ---------------------------------------------------- | ----------------------------------------------------------------------------------------------- | ------------------------------------------------------------------------------------ | ------------------------------------------------------------------------------------ | ------------------------------------------------------------------------------------------------------------------------------------------- |
| Code reusability                                     | 10 - Just one app                                                                               | 5 - Some custom styling required                                                     | 7 - A lot of default controls                                                        | 1 - Each platform requires dedicated styling                                                                                                |
| Language / learning curve                            | 7 - Shared javascript code / well known platform problems with plugins                          | 8 - Shared javascript code / well known platform                                     | 5 - Less known language - dart                                                       | 1 - Two platforms - two languages/frameworks                                                                                                |
| UI / Components library                              | 7 - Most of the ui components already provided                                                  | 3 - Requires styling / adjustments of many components                                | 8 - Most of the ui components already provided                                       | 10 - Native - hence everything is there                                                                                                     |
| Ecosystem / 3rd party libraries                      | 8 - Very good - all the dependencies are already available in web platform                      | 7 - Very good - all the main libraries already available                             | 7 - Good - all the main libraries already available                                  | 10 - Full - all the required plugins are there                                                                                              |
| Popularity                                           | 5 - Usually consider with the existing codebase - not so popular as a dedicated mobile platform | 7 - The most popular cross platform solution                                         | 7 - The youngest platform is still not popular yet but is very quickly rising        | 10 - The most popular approach                                                                                                              |
| Performance                                          | 4 - Average. especially with the complex layout                                                 | 7 - Good - close to native / small problems with custom components                   | 9 - Very high - mostly native (60fps)                                                | 10 - Native                                                                                                                                 |
| Native device functions                              | 3 - Some native dependencies are missing or requires commercial plugins                         | 7 - Most plugins already available                                                   | 6 - Most plugins already available                                                   | 10 - All                                                                                                                                    |
| Real world usage                                     | 5 - Not very popular for dedicated mobile apps (Sworkit)                                        | 8 - Very popular (facebook / instagram / airbnb)                                     | 7 - Recently gaining strong popularity (Hamilton App)                                | 10 - Very popular                                                                                                                           |
| Development process - instant feedback / live reload | 5 - Good - quick development with live reload / good cli support                                | 8 - Very good - quick development iteration due to the hot reload and system design  | 9 - Very good. quick development iteration due to the hot reload and system design   | 7 - Good                                                                                                                                    |
| Custom UI development(rich animations)               | 5 - Low control over the appearance of custom UI components                                     | 6 - It takes a lot of work to create rich animations                                 | 10 - Very rich animations and no restrictions in the UI appearance                   | 5 - Creating interesting animations on one platform is not very difficult but problems arise when we want IOS and Android to look the same  |
| Legacy                                               | 10 - No legacy                                                                                  | 10 - No legacy                                                                       | 10 - No legacy                                                                       | 1 - A lot of legacy                                                                                                                         |
| Dev team availability                                | 4 - A small number of developers                                                                | 8 - Lots of freelancers and a few companies                                          | 7 - Lots of freelancers and a few companies                                          | 10 - Most of the companies that offer mobile development                                                                                    |
| Sum                                                  | 73                                                                                              | 84                                                                                   | 92                                                                                   | 87                                                                                                                                          |
    </BlogTable>
    <p>{`Wow, that was detailed! Still with us? If so, let’s swap lenses to look at the overall picture.`}</p>
    <h2 {...{
      "id": "Pros--cons"
    }}>{`Pros & cons`}</h2>
    <p>{`See the main advantages and disadvantages of each technology.`}</p>
    <h3 {...{
      "id": "Ionic-1"
    }}>{`Ionic`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`Pros:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Independent platform`}</li>
      <li parentName="ul">{`Uses web technologies - broad and well-known ecosystem`}</li>
      <li parentName="ul">{`Looks the same on every device`}</li>
      <li parentName="ul">{`One codebase for everything`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Cons:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Smaller community`}</li>
      <li parentName="ul">{`Performance`}</li>
      <li parentName="ul">{`Worse user experience than fully-native solutions`}</li>
      <li parentName="ul">{`Lack of hot-reload-like mechanism - slower development process`}</li>
      <li parentName="ul">{`Complex native API access`}</li>
    </ul>
    <h3 {...{
      "id": "Flutter-1"
    }}>{`Flutter`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`Pros:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Hot Reload - faster development process`}</li>
      <li parentName="ul">{`Very fast rendering pipeline (120+ fps)`}</li>
      <li parentName="ul">{`Consistent across all devices including the older models`}</li>
      <li parentName="ul">{`Wide selection of widgets / UI components, including platform-specific ones`}</li>
      <li parentName="ul">{`Very customizable UI - perfect from complex interfaces incl. diagrams, animations, etc.`}</li>
      <li parentName="ul">{`Ideal for Minimum Viable Product`}</li>
      <li parentName="ul">{`Easy to scale`}</li>
      <li parentName="ul">{`Learn once, use everywhere`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Cons:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Smaller community but growing and very engaged`}</li>
      <li parentName="ul">{`Slightly smaller libraries (plugins) support`}</li>
      <li parentName="ul">{`Less familiar Dart language (but still simple and easy to learn)`}</li>
    </ul>
    <h3 {...{
      "id": "React-Native-1"
    }}>{`React Native`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`Pros:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Creating fully native mobile apps using JavaScript`}</li>
      <li parentName="ul">{`A large and active development community`}</li>
      <li parentName="ul">{`Speeds up development`}</li>
      <li parentName="ul">{`Easy to scale`}</li>
      <li parentName="ul">{`Widely used`}</li>
      <li parentName="ul">{`Learn once, use everywhere`}</li>
      <li parentName="ul">{`Better user experience than hybrid apps`}</li>
      <li parentName="ul">{`Fast refresh - faster development process`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Cons:`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Engineers must have some mobile development knowledge`}</li>
      <li parentName="ul">{`Slightly steeper learning curve than `}<a parentName="li" {...{
          "href": "https://ionicframework.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Ionic`}</a></li>
      <li parentName="ul">{`Only a portion of the code can be reused between React and `}<a parentName="li" {...{
          "href": "https://reactnative.dev",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`React Native`}</a>{` web projects`}</li>
      <li parentName="ul">{`Since a native UI is used - older devices might need to be additionally styled`}</li>
      <li parentName="ul">{`Noticeably lower performance than native applications`}</li>
      <li parentName="ul">{`High battery consumption`}</li>
    </ul>
    <p>{`This perspective reflects our understanding of developing different apps with all three technologies over the past decade. We know each case needs looking at individually, and we only ever make decisions based on a specific project’s variables.`}</p>
    <p>{`If you’ve read this far and realise your choice is a complex one, we invite you to tap into AllBright’s expertise via a consultation. We can evaluate your project, perform a tech trial and then give you a detailed and validated recommendation that’ll carry your project past most of the hazards that await. Contact us at `}<a parentName="p" {...{
        "href": "mailto:hello@allbright.io",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`hello@allbright.io`}</a></p>
    <h2 {...{
      "id": "Conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`The `}<strong parentName="p">{`established wisdom`}</strong>{` was that cross-platform development tools save time and help you reach a large number of users, but they aren’t perfect and will never replace native app development. `}<strong parentName="p"><em parentName="strong">{`According to our experience, the new wisdom is that the gap has narrowed and now, cross-platform development tools are an excellent first choice for both startups and established businesses.`}</em></strong>{` Ultimately, it would help if you based your final choice on your businesses’ strategic priorities, circumstances and access to relevant skills. We hope we’ve helped you make a solid choice from the three contenders we’ve featured in this article.`}</p>
    <h3 {...{
      "id": "What-is-our-choice"
    }}>{`What is our choice?`}</h3>
    <p>{`Over the years, we have been looking for the perfect technology to meet our needs as a team that wants to create the best apps possible. Our developers started with native technologies but were always looking for better solutions. Even though `}<a parentName="p" {...{
        "href": "https://ionicframework.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Ionic`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactnative.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`React Native`}</a>{` are great technologies, we couldn’t have chosen any other than `}<em parentName="p">{`Flutter`}</em>{`. Our team consists of developers specializing in this technology who, like us, love `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{` for its exceptional capabilities. We have already built many applications with this framework and strive to be the best Flutter-specific software house on the market.`}</p>
    <p>{`If you want to create an application in this technology, contact us. AllBright has a stellar track record in the development of cross-platform apps using `}<a parentName="p" {...{
        "href": "https://flutter.dev",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Flutter`}</a>{`. We’d be delighted to listen to your requirements and suggest ways we could begin a partnership as your mobile app developer. Contact us at `}<a parentName="p" {...{
        "href": "mailto:hello@allbright.io",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`hello@allbright.io`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      